import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function TermsPage({ navigation }) {


    return (
        <View style={styles.root}>
            <Text style={styles.header}>{`Terms of Use`}</Text>
            <Text style={styles.subHeader}>{`Effective Date: February 1, 2024`}</Text>

            <Text style={styles.description}>{`Greetings and welcome to PerfectCruiseChoice.com (referred to as ""PerfectCruiseChoice.com"", ""we"", ""our"", or ""us""). At PerfectCruiseChoice.com, we're dedicated to providing you with an exceptional cruise booking experience. Our platform connects cruise enthusiasts with extraordinary sailing adventures across the globe, all accessible through various devices, including computers, mobile phones, and tablets (collectively referred to as the “Services”). Before you embark on your journey with us, please take a moment to review the following terms and conditions (the “Terms of Use”). These terms, coupled with any additional policies referenced herein, outline the rules and guidelines for using our Services and form a binding agreement between you and PerfectCruiseChoice.com. Whether you're just visiting or joining our community, by accessing or utilizing our Services, you agree to abide by these Terms of Use.`}</Text>
            <Text style={styles.title}>{`1. Membership and Eligibility`}</Text>
            <Text style={styles.description}>{`To unlock the full spectrum of features offered by our Services, you may need to register for an account and provide us with certain details, such as a valid email address. By signing up for an account, you confirm that all information provided is accurate, complete, and lawful. It's crucial to keep your account information up to date, and you can rest assured that we prioritize your privacy. For details on how we handle your information, please refer to our Privacy Policy.`}</Text>
            <Text style={styles.description}>{`We kindly ask that you refrain from using false identities, impersonating others, or accessing accounts without proper authorization. PerfectCruiseChoice.com reserves the right to request a change of username at any time for any reason. Please safeguard your username, password, and account information diligently. Never share your password with others, and promptly notify PerfectCruiseChoice.com if you suspect any unauthorized access to your account.`}</Text>
            <Text style={styles.title}>{`2. Limited License`}</Text>
            <Text style={styles.description}>{`Subject to these Terms of Use, PerfectCruiseChoice.com grants you a personal, non-exclusive, revocable, non-transferable license to access and use the Services strictly for personal purposes, provided you comply with all terms and conditions outlined here, including our Privacy Policy. We retain all rights to the Services and any associated content, including intellectual property rights. You agree not to reproduce, modify, distribute, or exploit any content, software, or services available through our platform without our express consent.`}</Text>
            <Text style={styles.title}>{`3. Intellectual Property Rights`}</Text>
            <Text style={styles.description}>{`Our Services contain proprietary intellectual property owned by PerfectCruiseChoice.com or our licensors, protected by domestic and international laws. We retain full ownership and control over all content, trademarks, copyrights, and other intellectual property associated with the Services. Your use of our platform does not confer any ownership rights to you.`}</Text>
            <Text style={styles.title}>{`4. Proper Use of the Services`}</Text>
            <Text style={styles.description}>{`Our Services are designed for specific purposes outlined herein. You agree not to engage in any activities that may harm, disrupt, or interfere with the functionality of our platform. Furthermore, you commit to using our Services in compliance with all applicable laws and regulations.`}</Text>
            <Text style={styles.title}>{`5. Changes and Termination`}</Text>
            <Text style={styles.description}>{`PerfectCruiseChoice.com reserves the right to modify, suspend, or terminate the Services or these Terms of Use at any time without prior notice. Any changes to these terms will be communicated through our platform or in accordance with applicable legal requirements. By continuing to use our Services after any modifications, you indicate your acceptance of the updated terms.`}</Text>
            <Text style={styles.title}>{`6. Get in Touch`}</Text>
            <Text style={styles.description}>{`If you have any questions regarding our Terms of Use or Privacy Policy, please don't hesitate to reach out to us at letsgo@PerfectCruiseChoice.com. We're here to assist you every step of the way.`}</Text>

            <Text style={styles.description}>{`Embark on your perfect cruise adventure with PerfectCruiseChoice.com today!`}</Text>
        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        header: {
            color: "#0B3C49",
            fontSize: 22,
            fontWeight: "700",
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        subHeader: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            position: "relative",
            paddingVertical: 10,
        },
    }
)
export default TermsPage;
