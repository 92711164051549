import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function PrivacyPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={styles.header}>{`Privacy Policy `}</Text>
            <Text style={styles.subHeader}>{`Last Updated: February 1, 2024`}</Text>
            <Text style={styles.description}>{`At PerfectCruiseChoice.com (""PerfectCruiseChoice.com"", ""we"", ""our"", or ""us""), we are committed to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect the information we collect from you when you use our website and services.`}</Text>
            <Text style={styles.title}>{`Information We Collect`}</Text>
            <Text style={styles.description}>{`When you visit PerfectCruiseChoice.com or use our services, we may collect certain information, including:`}</Text>
            <Text style={styles.subDescription}>{`\tPersonal Information: When you register for an account, make a booking, or interact with our website, we may collect personal information such as your name, email address, phone number, and billing information.`}</Text>
            <Text style={styles.subDescription}>{`\tUsage Information: We may collect information about your interactions with our website, including pages visited, links clicked, and other actions taken.`}</Text>
            <Text style={styles.subDescription}>{`\tDevice and Log Information: We automatically collect information about your device and usage patterns, such as your IP address, browser type, and operating system.`}</Text>
            <Text style={styles.subDescription}>{`\tCookies and Similar Technologies: We use cookies and similar technologies to enhance your browsing experience and collect information about how you use our website.`}</Text>
            <Text style={styles.title}>{`How We Use Your Information`}</Text>
            <Text style={styles.description}>{`We may use the information we collect for various purposes, including:`}</Text>
            <Text style={styles.subDescription}>{`\tProviding and improving our services`}</Text>
            <Text style={styles.subDescription}>{`\tPersonalizing your experience`}</Text>
            <Text style={styles.subDescription}>{`\tCommunicating with you about your bookings and inquiries`}</Text>
            <Text style={styles.subDescription}>{`\tAnalyzing usage patterns and trends`}</Text>
            <Text style={styles.subDescription}>{`\tPreventing fraud and ensuring the security of our website`}</Text>
            <Text style={styles.title}>{`How We Share Your Information`}</Text>
            <Text style={styles.description}>{`We may share your information with third parties in the following circumstances:`}</Text>
            <Text style={styles.subDescription}>{`\tWith service providers who help us operate our website and provide our services`}</Text>
            <Text style={styles.subDescription}>{`\tWith our business partners, such as cruise lines and travel agencies, to facilitate bookings and transactions`}</Text>
            <Text style={styles.subDescription}>{`\tIn response to legal requests or to comply with applicable laws and regulations`}</Text>
            <Text style={styles.subDescription}>{`\tIn connection with a merger, acquisition, or sale of assets`}</Text>

            <Text style={styles.title}>{`Your Choices`}</Text>
            <Text style={styles.description}>{`You have certain choices regarding the collection and use of your information:`}</Text>
            <Text style={styles.subDescription}>{`\tYou can choose not to provide certain information, although this may limit your ability to use certain features of our website.`}</Text>
            <Text style={styles.subDescription}>{`\tYou can opt out of receiving promotional communications from us by following the instructions provided in those communications.`}</Text>

            <Text style={styles.title}>{`Security of Your Information`}</Text>
            <Text style={styles.description}>{`We take reasonable measures to protect the security of your information and prevent unauthorized access, disclosure, or alteration.`}</Text>
            <Text style={styles.title}>{`Children's Privacy`}</Text>
            <Text style={styles.description}>{`Our website and services are not directed to children under the age of 13, and we do not knowingly collect personal information from children.`}</Text>
            <Text style={styles.title}>{`Changes to This Privacy Policy`}</Text>
            <Text style={styles.description}>{`We may update this Privacy Policy from time to time, and any changes will be posted on this page with a revised effective date.`}</Text>

            <Text style={styles.title}>{`Contact Us`}</Text>
            <Text style={styles.description}>{`If you have any questions or concerns about our Privacy Policy or our practices regarding your personal information, please contact us at letsgo@PerfectCruiseChoice.com.`}</Text>
            <Text style={styles.description}>{`Embark on your dream cruise adventure with confidence, knowing that your privacy is protected at PerfectCruiseChoice.com.`}</Text>
        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        header: {
            color: "#0B3C49",
            fontSize: 22,
            fontWeight: "700",
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        subHeader: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            position: "relative",
            paddingVertical: 10,
        },
        subDescription: {
            color: "#0B3C49",
            fontSize: 15,
            fontWeight: "400",
            position: "relative",
            paddingVertical: 5,
        },
    }
)

export default PrivacyPage;
