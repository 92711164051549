import { View, Text, StyleSheet, ImageBackground, Button, TextInput } from 'react-native';
import { useState } from 'react';
import Footer from '../components/Footer';
import validator from 'validator';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { isMobile } from '../helpers/deviceUtils';
import { Pressable } from 'react-native-web';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/PageHeader';

const isMobileDevice = isMobile();


function AutoHomePage({ navigation }) {
    const [partySize, setPartySize] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const styles = isMobileDevice ? mobileStyles : desktopStyles;
    const navigate = useNavigate()

    return (
        <View style={styles.root}>
            <ImageBackground source={require('../assets/background.png')}
                style={styles.image} />
            <View style={styles.darkBackground} />

            <PageHeader />

            <View style={styles.contentView}>

                <View style={styles.mainContent} >
                    <Text style={styles.description}>{messages.description}</Text>
                    {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}

                    <TextInput style={styles.textInput}
                        placeholder={messages.partySize}
                        placeholderTextColor={'rgba(25,28,31,0.5)'}
                        value={partySize}
                        inputMode='numeric'
                        onChangeText={(newValue) => {
                            setPartySize(newValue);
                        }} />


                    <Pressable style={[styles.buttonView]}
                        onPress={() => {
                            const isValid = validator.isNumeric(partySize, "US");
                            if (isValid) {
                                setErrorMessage("");
                                navigate({
                                    pathname: '/info',
                                    search: '?partySize=' + partySize,
                                })
                            } else {
                                setErrorMessage(ERROR_MESSAGES.GENERIC)
                            }
                        }}>
                        <Text style={[styles.buttonContent]}>{messages.seeQuotes}</Text>
                    </Pressable>
                </View>
            </View>
            <Footer navigation={navigation} style={styles.footer}>

            </Footer>

        </View>
    );
}

const baseStyles = StyleSheet.create(
    {
        root: {
            backgroundColor: '#91E9EC',
        },
        darkBackground: {
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.4)"
        },
        mainContent: {
            width: 600,
            justifyContent: "center",
            alignItems: "center",
            position: "absolute"
        },
        contentView: {
            height: window.innerHeight,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "absolute",
        },
        image: {
            width: "100%",
            height: window.innerHeight,
            resizeMode: 'cover',
        },
        description: {
            color: "white",
            fontWeight: "700",
            fontFamily: 'TenorSans-Regular',
            textAlign: "center",
        },
        error: {
            color: "red",
            fontWeight: "400",
            fontFamily: 'TenorSans-Regular',
            lineHeight: 24
        },
        textInput: {
            backgroundColor: "white",
            marginTop: 40,
            borderRadius: 10,
            fontWeight: 400,
            fontFamily: 'TenorSans-Regular',
            padding: 15,
        },
        footer: {
            position: "absolute",
            marginTop: window.innerHeight * 0.94,
            flexDirection: "row",
            justifyContent: "center",
            width: "100%"
        },
        buttonView: {
            backgroundColor: "#31A8FF",
            position: "relative",
            marginTop: 40,
            width: 180,
            borderRadius: 10,
            paddingVertical: 5,
            paddingHorizontal: 15,
            alignItems: "center",
            justifyContent: "center",
        },
        buttonContent: {
            color: "#FFFFFF",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "700",
            fontFamily: 'TenorSans-Regular',
            padding: 14,
        },
    }
)

const desktopStyles = StyleSheet.create(
    {
        ...baseStyles,
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "center"
        },
        description: {
            ...baseStyles.description,
            fontSize: 64,
            lineHeight: 66
        },
        error: {
            ...baseStyles.error,
            fontSize: 24,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 12,
            width: 340
        },
    }
)


const mobileStyles = StyleSheet.create(
    {
        ...baseStyles,
        root: {
            ...baseStyles.root,
            height: "100%",
            width: '100%',
        },
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "flex-start"
        },
        mainContent: {
            marginLeft: "10%",
            width: "80%",
            position: "absolute",
            marginTop: 65,
            paddingRight: 20
        },
        description: {
            ...baseStyles.description,
            fontSize: 30,
            lineHeight: 38
        },
        error: {
            ...baseStyles.error,
            fontSize: 20,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 10,
            width: "60%"
        },
    }
)

const messages = {
    description: "Find your perfect sailing getaway today!",
    partySize: "APROX HOW MANY PEOPLE IN YOUR PARTY?",
    seeQuotes: "LET'S GO!",
}

export default AutoHomePage;
