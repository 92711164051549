import { View, Text, StyleSheet, Linking } from 'react-native';
import { useNavigate } from 'react-router-dom';

import { isMobile } from '../helpers/deviceUtils';
const isMobileDevice = isMobile();

const PageHeader = () => {
    const styles = isMobileDevice ? mobileStyles : desktopStyles;

    return (
        <View style={styles.header}>
            <Text style={styles.title}>{"Perfect Cruise Choice"}</Text>
        </View>);
}

const baseStyles = StyleSheet.create(
    {
        header: {
            height: window.innerHeight * 0.1,
            justifyContent: "center",
            alignItems: "center",
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "800",
            fontFamily: 'TenorSans-Regular',
        },
    }
)

const desktopStyles = StyleSheet.create(
    {
        ...baseStyles,
        header: {
            ...baseStyles.header,
            marginLeft: 215,
            flexDirection: "row",
            position: "absolute"
        },
        title: {
            ...baseStyles.title,
            fontSize: 22,
        },
    }
)

const mobileStyles = StyleSheet.create(
    {
        ...baseStyles,
        header: {
            ...baseStyles.header,
            marginLeft: "10%",
            flexDirection: "row",
            alignItems: "center"
        },
    }
)

export default PageHeader;